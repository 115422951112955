/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useEffect, createContext } from 'react'
import useAuth from '../hook/useAuth'
import {
    systemID,
    getToken,
    getRefreshToken,
} from '../functionTool/functionTool.js'

export const AuthContext = createContext()

export default function ProviderAuth({ children }) {
    const auth = useAuth()

    useEffect(() => {
        if ('Worker' in window && auth.user && auth.user.token) {
            const worker = new Worker(
                `${process.env.PUBLIC_URL}/tokenRefresher.js`
            )

            // 从localStorage或其他存储中读取上次保存的expiresAt
            const storedExpiresAt = localStorage.getItem('expiresAt')
            const now = Date.now()
            let expiresIn = parseInt(storedExpiresAt, 10) - now

            // 如果已过期或没有存储的expiresAt，立即刷新
            if (isNaN(expiresIn) || expiresIn <= 0) {
                expiresIn = 0 // 立即刷新
            }

            worker.addEventListener('message', (e) => {
                const { token, expiresAt, error } = e.data
                if (token) {
                    // 使用从Worker接收到的新token更新状态
                    auth.AuthUser({
                        ...auth.user,
                        token: token,
                        expiresAt: expiresAt,
                    })
                } else if (error) {
                    console.error(error)
                }
            })

            // 发送初始化消息以启动定时刷新流程
            worker.postMessage({
                action: 'INIT_REFRESH',
                refreshTokenUrl: `${process.env.REACT_APP_APIGATEWAY_URL}/auth/refresh`,
                refreshToken: getRefreshToken(),
                systemID: systemID,
                expiresIn: expiresIn, // 假设 expiresIn 是刷新周期，例如30分钟
                token: getToken(),
            })

            return () => worker.terminate() // 清理函数，在组件卸载时终止Worker
        }
    }, [auth.user])

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
