/* eslint-disable no-undef */
import React, { useState, useContext } from 'react'
import useRWD from '../../hook/useRWD'
import { Navigate, Link } from 'react-router-dom'
import useLocalStorage from '../../hook/useLocalStorage'
import { Button, Checkbox, Form, Input } from 'antd'
import {
    UserOutlined,
    LockOutlined,
    ApartmentOutlined,
    FormOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'
import { AuthContext } from '../../store/AuthProvider'
import bg from '../../images/loginbg.jpg'
import bg_m from '../../images/loginbg_m.jpg'
import logo_w from '../../images/logo_w.svg'
import {
    openNotification,
    expiresIn,
    systemID,
} from '../../functionTool/functionTool'
import {
    GetEncryptedString,
    GetDecryptedString,
} from '../../functionTool/encryption'

const Login = () => {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useLocalStorage('username', '')
    const [pass, setPass] = useLocalStorage('password', '')
    const [rememberAccout, setRememberAccout] = useLocalStorage(
        'remember',
        'false'
    )
    const device = useRWD()
    const { AuthUser, user } = useContext(AuthContext)

    const getAuth = (systemID, account, password) => {
        // eslint-disable-next-line no-undef
        const res = axios
            .post(`${process.env.REACT_APP_APIGATEWAY_URL}/auth/login`, {
                systemID,
                account,
                password,
            })
            .then((response) => {
                if (response.data.status === true) {
                    setLoading(false)
                    const expiresAt = Date.now() + expiresIn
                    AuthUser({
                        ...response.data.data,
                        expiresAt,
                    })
                }
                if (response.data.status === false) {
                    setLoading(false)
                    openNotification('top', response.data.msg)
                }
            })
        return res
    }

    const onFinish = (values) => {
        setLoading(true)
        const username = values.username.trim()
        const password = values.password.trim()
        getAuth(systemID, username, password)
        if (values.remember) {
            setName(GetEncryptedString(username))
            setPass(GetEncryptedString(password))
            setRememberAccout(values.remember)
        } else {
            setName('')
            setPass('')
            setRememberAccout('')
        }
    }
    if (user) return <Navigate to="/" />

    return (
        <div
            className="app-login"
            style={
                device !== 'hisesPC' && device !== 'PC'
                    ? { backgroundImage: `url(${bg_m})` }
                    : { backgroundImage: `url(${bg})` }
            }
        >
            <div className="app-login-header">
                <div className="container">
                    <div className="app-login-header-menu">
                        <div className="logo">
                            <img src={logo_w} alt="微電能源-能源管理系統" />
                        </div>
                        <div className="menu">
                            <ol>
                                <li>
                                    <Button
                                        type="link"
                                        href="https://www.rfdme.com/"
                                        icon={<ApartmentOutlined />}
                                    >
                                        微電官網
                                    </Button>
                                </li>
                                <li>
                                    <Button type="link" icon={<UserOutlined />}>
                                        個人訊息
                                    </Button>
                                </li>
                                <li>
                                    <Button type="link" icon={<FormOutlined />}>
                                        註冊新帳號
                                    </Button>
                                </li>
                            </ol>
                        </div>
                        <div className="btn">
                            <Button ghost>聯絡我們</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-login-content">
                <div className="container">
                    <div className="app-login-form">
                        <h1>微電能源管理系統</h1>
                        {process.env.REACT_APP_STAGE_NAME !== '正式區' && (
                            <div className="stage-name">
                                {process.env.REACT_APP_STAGE_NAME}
                            </div>
                        )}
                        <Form
                            name="login"
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                remember: rememberAccout,
                                username: GetDecryptedString(name),
                                password: GetDecryptedString(pass),
                            }}
                            size="large"
                            autoComplete="off"
                            onFieldsChange={(changedFields) => {
                                if (changedFields[0].name[0] === 'remember')
                                    setRememberAccout((value) => !value)
                            }}
                        >
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Username!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        <UserOutlined className="site-form-item-icon" />
                                    }
                                    placeholder="Username"
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={
                                        <LockOutlined className="site-form-item-icon" />
                                    }
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>記住我</Checkbox>
                                </Form.Item>

                                <Link className="login-form-forgot">
                                    忘記密碼
                                </Link>
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    block
                                >
                                    登入
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="app-login-footer">
                <div className="container">
                    <p>
                        ©{moment().year()} Micro Electricity Co., Ltd. All
                        rights reserved.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login
