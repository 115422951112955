/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'
import moment from 'moment'
import useLocalStorage from '../hook/useLocalStorage'
import { getDateTimeRangeToEnd } from '../functionTool/functionTool.js'

export const DashboardContext = createContext()

export default function DashboardProvider({ children }) {
    const [customMode, setCustomMode] = useState(true)
    const [endOfThatDay, setEndOfThatDay] = useState(false)
    const [listID, setListID] = useLocalStorage('listID', '')
    const [date, setDate] = useState(moment())
    const [refLine, setRefLine] = useState({
        active: false,
        payload: null,
    })
    const { startDatetime, endDatetime } = getDateTimeRangeToEnd('day', date) //日、週、月、年開始時間、結束時間
    const [offset, setOffset] = useState({
        refAreaLeft: '',
        refAreaRight: '',
        left: Date.parse(startDatetime),
        right: Date.parse(endDatetime),
    })
    const [section, setSection] = useState({
        left: 0,
        right: 0,
        fetch: false,
    })

    const querys = {
        date,
        setDate,
        listID,
        setListID,
        refLine,
        setRefLine,
        offset,
        setOffset,
        section,
        setSection,
        endOfThatDay,
        setEndOfThatDay,
        customMode,
        setCustomMode,
    }
    // console.log(querys)
    return (
        <DashboardContext.Provider value={querys}>
            {children}
        </DashboardContext.Provider>
    )
}
