/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'
import moment from 'moment'
import useLocalStorage from '../hook/useLocalStorage'
import {
    getTimelinePeriod,
    getDateTimeRangeToEnd,
    defaultLayouts,
} from '../functionTool/functionTool.js'

export const AnalysisContext = createContext()

export default function AnalysisProvider({ children }) {
    const [date, setDate] = useState(moment())
    const [dateline, setDateline] = useState('day')
    const [timezone, setTimezone] = useState('8')
    const [tempLayouts, setTempLayouts] = useState({})
    const [listID, setListID] = useLocalStorage('listID', '')
    const [layouts, setLayouts] = useLocalStorage('layouts', defaultLayouts)
    const [analysisMenu, setAnalysisMenu] = useLocalStorage('analysisMenu', [])
    const [dragNum, setDragNum] = useState()
    const [isDraggable, setIsDraggable] = useState(false)
    const [mounted, setMounted] = useState(false)
    const timescale = getTimelinePeriod(dateline)
    const { startDatetime, endDatetime } = getDateTimeRangeToEnd(dateline, date) //日、週、月、年開始時間、結束時間

    const [offset, setOffset] = useState({
        refAreaLeft: '',
        refAreaRight: '',
        left: Date.parse(startDatetime),
        right: Date.parse(endDatetime),
    })
    const [section, setSection] = useState({
        left: 0,
        right: 0,
        fetch: false,
    })

    const querys = {
        date,
        setDate,
        listID,
        setListID,
        dateline,
        setDateline,
        startDatetime,
        endDatetime,
        timezone,
        setTimezone,
        timescale,
        offset,
        setOffset,
        section,
        setSection,
        isDraggable,
        setIsDraggable,
        layouts,
        setLayouts,
        analysisMenu,
        setAnalysisMenu,
        dragNum,
        setDragNum,
        tempLayouts,
        setTempLayouts,
        mounted,
        setMounted,
    }
    // console.log(querys)
    return (
        <AnalysisContext.Provider value={querys}>
            {children}
        </AnalysisContext.Provider>
    )
}
