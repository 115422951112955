import { useState } from 'react'
import {
    GetEncryptedString,
    GetDecryptedString,
} from '../functionTool/encryption'
import { SessionClear } from '../functionTool/functionTool'

const GetInitUser = () => {
    const userName = window.localStorage.getItem('userName')
    const expiresAt = window.localStorage.getItem('expiresAt')
    const token = GetDecryptedString(window.localStorage.getItem('token'))
    const refreshToken = GetDecryptedString(
        window.localStorage.getItem('refreshToken')
    )

    if (userName && token) {
        return {
            userName,
            token,
            refreshToken,
            expiresAt,
        }
    } else {
        return null
    }
}

const useAuth = () => {
    const [user, setUser] = useState(GetInitUser())

    const AuthUser = (authUser) => {
        if (authUser) {
            authUser.token = 'Bearer ' + authUser.token
            window.localStorage.setItem('expiresAt', authUser.expiresAt)
            window.localStorage.setItem('userName', authUser.userName)
            window.localStorage.setItem(
                'token',
                GetEncryptedString(authUser.token)
            )
            window.localStorage.setItem(
                'refreshToken',
                GetEncryptedString(authUser.refreshToken)
            )
            setUser(authUser)
        }
    }
    const UnAuthUser = () => {
        SessionClear()
        setUser(null)
    }

    return { user, AuthUser, UnAuthUser }
}
export default useAuth
