import React from 'react'
import { notification, Badge } from 'antd'
import { Link } from 'react-router-dom'
import {
    HomeOutlined,
    ClusterOutlined,
    SettingOutlined,
    LoadingOutlined,
    FileSearchOutlined,
    ReconciliationOutlined,
    FundViewOutlined,
    TableOutlined,
    BarChartOutlined,
    LineChartOutlined,
    PlayCircleOutlined,
    ToolOutlined,
    CloseCircleFilled,
    MinusCircleFilled,
    InfoCircleFilled,
    QuestionCircleFilled,
    FileDoneOutlined,
} from '@ant-design/icons'
import { MarkerType } from 'reactflow'
import axios from 'axios'
import moment from 'moment'
import { GetDecryptedString } from './encryption'
import { scene1, scene2, scene5 } from '../mockData/data.js'

//system ID
export const systemID = '003'

//query時間點
export const timePoint = moment().format('YYYY/MM/DD')

//deviceStartTime
export const deviceStartTime = '2022/01/01 00:00:00'

//碳排公斤轉換樹量
export const carbonTransformToTreeRatio = 0.084

//時間區間
export const DEFAULT_TIMEZONE = '8'

//刷新時間
export const refetchInterval = 1000 * 60 * 5

//加0
export const addZero = (i) => {
    if (i < 10) {
        i = '0' + i
    }
    return i
}

//轉換時、分
export const formatTime = (current_datetime) => {
    let formatted_time =
        addZero(current_datetime.getHours()) +
        ':' +
        addZero(current_datetime.getMinutes())
    return formatted_time
}

//轉換分
export const formatMinutes = (current_datetime, index) => {
    let hours = current_datetime.getHours();
    let minutes = current_datetime.getMinutes();
    if (hours === 0 && minutes === 0 && index > 0) return 1440
    let formatted_minutes = hours * 60 + minutes
    return formatted_minutes
}

//物件修改時間返回物件function
export const objTimeChange = (arr) => {
    const newArr = []
    if (arr) {
        arr.forEach((item, index) => {
            newArr[index] = { ...item, time: formatTime(new Date(item.time)) }
        })
    } else {
        return
    }
    return newArr
}

//物件修改時間轉換成分鐘
export const objTimeChangeToMinutes = (arr) => {
    const newArr = []
    if (arr) {
        arr.forEach((item, index) => {
            newArr[index] = {
                ...item,
                time: formatMinutes(new Date(item.time),index),
            }
        })
    } else {
        return
    }
    return newArr
}

//物件修改時間轉換成時間戳
export const objTimeChangeToTimestamp = (arr) => {
    if (!arr || arr.length === 0) return []

    return arr.map((item) => ({
        ...item,
        time: moment(item.time || item.date || item.endTimestamp)
            .seconds(0)
            .valueOf(),
    }))
}

//轉換日期
export const formatDate = (current_datetime) => {
    let formatted_date =
        current_datetime.getFullYear() +
        '-' +
        (current_datetime.getMonth() + 1) +
        '-' +
        current_datetime.getDate()
    return formatted_date
}

//計算開始結束時間 (endDatetime會抓到日、週、月、年的結尾多一秒)
export const getDateTimeRange = (
    dateline,
    date,
    usePreviousPeriod = false,
    endOfThatDay = false,
    demo = false
) => {
    if (date === 'now') date = moment()
    if (!date || !moment.isMoment(date))
        return { startDatetime: null, endDatetime: null }

    // 設置起始跟結束時間
    let startDatetime = date
        .clone()
        .startOf(dateline)
        .format('YYYY/MM/DD HH:mm:ss')
    let endDatetime = date.clone().endOf(dateline).format('YYYY/MM/DD HH:mm:ss')

    // 如果dateline是天或週，endDatetime則多抓一秒
    if (dateline === 'day' || dateline === 'week') {
        endDatetime = date
            .clone()
            .endOf(dateline)
            .add(1, 'seconds')
            .format('YYYY/MM/DD HH:mm:ss')
    }

    // 開始時間為"設備"購買設置時間，結束時間為現在
    if (dateline === 'accumulatedToNow') {
        startDatetime = deviceStartTime
        endDatetime = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss')
        return { startDatetime, endDatetime }
    }

    // 那天的結束時間
    if (endOfThatDay) {
        endDatetime = date
            .clone()
            .endOf('day')
            .add(1, 'seconds')
            .format('YYYY/MM/DD HH:mm:ss')
        return { startDatetime, endDatetime }
    }

    // 如果是同年、月、週、日，結束時間為現在時間
    if (moment().isSame(date, dateline)) {
        endDatetime = moment()
            .subtract(15, 'minutes') //抓15分鐘前的資料
            .format('YYYY/MM/DD HH:mm:ss')
        return { startDatetime, endDatetime }
    }

    // demo模式，結束時間為那天現在的時、分
    if (demo) {
        endDatetime =
            date.format('YYYY/MM/DD') + ' ' + moment().format('HH:mm:ss')
        return { startDatetime, endDatetime }
    }

    // 抓取前一個週期的開始跟結束時間
    if (usePreviousPeriod) {
        const currentTime = moment().format('HH:mm:ss')
        const currentDayOfMonth = moment().date()
        const currentDayOfWeek = moment().day()
        let adjustedDate = date.clone()

        switch (dateline) {
            case 'day':
                adjustedDate = date.clone().subtract(1, 'days')
                if (moment().isSame(date, 'day')) {
                    endDatetime =
                        adjustedDate.format('YYYY/MM/DD') + ' ' + currentTime
                } else {
                    endDatetime = adjustedDate
                        .endOf('day')
                        .format('YYYY/MM/DD HH:mm:ss')
                }
                break
            case 'week':
                adjustedDate = adjustedDate.subtract(1, 'weeks')
                if (moment().isSame(date, 'week')) {
                    endDatetime =
                        adjustedDate
                            .day(currentDayOfWeek)
                            .format('YYYY/MM/DD') +
                        ' ' +
                        currentTime
                } else {
                    endDatetime = adjustedDate
                        .endOf('week')
                        .format('YYYY/MM/DD HH:mm:ss')
                }
                break
            case 'month':
                adjustedDate = adjustedDate.subtract(1, 'months')
                if (moment().isSame(date, 'month')) {
                    let daysInMonth = adjustedDate.daysInMonth()
                    let targetDay =
                        currentDayOfMonth <= daysInMonth
                            ? currentDayOfMonth
                            : daysInMonth
                    endDatetime =
                        adjustedDate.date(targetDay).format('YYYY/MM/DD') +
                        ' ' +
                        currentTime
                } else {
                    endDatetime = adjustedDate
                        .endOf('month')
                        .format('YYYY/MM/DD HH:mm:ss')
                }
                break
            case 'year':
                adjustedDate = adjustedDate.subtract(1, 'years')
                if (moment().isSame(date, 'year')) {
                    endDatetime =
                        adjustedDate
                            .dayOfYear(date.dayOfYear())
                            .format('YYYY/MM/DD') +
                        ' ' +
                        currentTime
                } else {
                    endDatetime = adjustedDate
                        .endOf('year')
                        .format('YYYY/MM/DD HH:mm:ss')
                }
                break
            default:
                break
        }
        return { startDatetime, endDatetime }
    }

    return { startDatetime, endDatetime }
}

//計算開始結束時間 (endDatetime只抓到日、週、月、年的結尾)
export const getDateTimeRangeEndOfDate = (
    dateline,
    date,
    usePreviousPeriod = false,
    endOfThatDay = false,
    demo = false
) => {
    if (date === 'now') date = moment()
    if (!date || !moment.isMoment(date))
        return { startDatetime: null, endDatetime: null }

    // 設置起始跟結束時間
    let startDatetime = date
        .clone()
        .startOf(dateline)
        .format('YYYY/MM/DD HH:mm:ss')
    let endDatetime = date.clone().endOf(dateline).format('YYYY/MM/DD HH:mm:ss')

    // 開始時間為"設備"購買設置時間，結束時間為現在
    if (dateline === 'accumulatedToNow') {
        startDatetime = deviceStartTime
        endDatetime = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss')
        return { startDatetime, endDatetime }
    }

    // 那天的結束時間
    if (endOfThatDay) {
        endDatetime = date.clone().endOf('day').format('YYYY/MM/DD HH:mm:ss')
        return { startDatetime, endDatetime }
    }

    // 如果是同年、月、週、日，結束時間為現在時間
    if (moment().isSame(date, dateline)) {
        endDatetime = moment()
            .subtract(15, 'minutes')
            .format('YYYY/MM/DD HH:mm:ss')
        return { startDatetime, endDatetime }
    }

    // demo模式，結束時間為那天現在的時、分
    if (demo) {
        endDatetime =
            date.format('YYYY/MM/DD') + ' ' + moment().format('HH:mm:ss')
        return { startDatetime, endDatetime }
    }

    // 抓取前一個週期的開始跟結束時間
    if (usePreviousPeriod) {
        const currentTime = moment().format('HH:mm:ss')
        const currentDayOfMonth = moment().date()
        const currentDayOfWeek = moment().day()
        let adjustedDate = date.clone()

        switch (dateline) {
            case 'day':
                adjustedDate = date.clone().subtract(1, 'days')
                if (moment().isSame(date, 'day')) {
                    endDatetime =
                        adjustedDate.format('YYYY/MM/DD') + ' ' + currentTime
                } else {
                    endDatetime = adjustedDate
                        .endOf('day')
                        .format('YYYY/MM/DD HH:mm:ss')
                }
                break
            case 'week':
                adjustedDate = adjustedDate.subtract(1, 'weeks')
                if (moment().isSame(date, 'week')) {
                    endDatetime =
                        adjustedDate
                            .day(currentDayOfWeek)
                            .format('YYYY/MM/DD') +
                        ' ' +
                        currentTime
                } else {
                    endDatetime = adjustedDate
                        .endOf('week')
                        .format('YYYY/MM/DD HH:mm:ss')
                }
                break
            case 'month':
                adjustedDate = adjustedDate.subtract(1, 'months')
                if (moment().isSame(date, 'month')) {
                    let daysInMonth = adjustedDate.daysInMonth()
                    let targetDay =
                        currentDayOfMonth <= daysInMonth
                            ? currentDayOfMonth
                            : daysInMonth
                    endDatetime =
                        adjustedDate.date(targetDay).format('YYYY/MM/DD') +
                        ' ' +
                        currentTime
                } else {
                    endDatetime = adjustedDate
                        .endOf('month')
                        .format('YYYY/MM/DD HH:mm:ss')
                }
                break
            case 'year':
                adjustedDate = adjustedDate.subtract(1, 'years')
                if (moment().isSame(date, 'year')) {
                    endDatetime =
                        adjustedDate
                            .dayOfYear(date.dayOfYear())
                            .format('YYYY/MM/DD') +
                        ' ' +
                        currentTime
                } else {
                    endDatetime = adjustedDate
                        .endOf('year')
                        .format('YYYY/MM/DD HH:mm:ss')
                }
                break
            default:
                break
        }
        return { startDatetime, endDatetime }
    }

    return { startDatetime, endDatetime }
}

//計算開始結束時間 (結束時間為當日結束時間pv跟load predict用)
export const getDateTimeRangeToEnd = (dateline, date) => {
    let startDatetime
    let endDatetime
    if (date === 'now') date = moment()
    if (dateline === 'accumulatedToNow') {
        startDatetime = deviceStartTime
        endDatetime = moment()
            .endOf('day')
            .add(1, 'seconds')
            .format('YYYY/MM/DD HH:mm:ss')
    } else {
        startDatetime = date
            .clone()
            .startOf(dateline)
            .format('YYYY/MM/DD HH:mm:ss')
        endDatetime = date
            .clone()
            .endOf(dateline)
            .add(1, 'seconds')
            .format('YYYY/MM/DD HH:mm:ss')
    }
    return { startDatetime, endDatetime }
}

//時間scale
const timeScales = (timescale) => {
    switch (timescale) {
        case 'quarter':
            return { unit: 'minutes', value: 15 }
        case 'daily':
        case 'weekly':
            return { unit: 'days', value: 1 }
        case 'monthly':
            return { unit: 'months', value: 1 }
        case 'yearly':
            return { unit: 'years', value: 1 }
        default:
            return { unit: 'years', value: 1 }
    }
}

//生成5分鐘的時間陣列
const generateTimeArrOfFiveMinutes = (start, end) => {
    const timestamps = []
    let startDate = moment(start, 'YYYY/MM/DD HH:mm:ss')
    const endDate = moment(end, 'YYYY/MM/DD HH:mm:ss')
    const unit = 'minutes'
    const value = 5 // 每5分钟生成一次数据

    const minutes = startDate.minute()
    const remainder = minutes % 5
    if (remainder !== 0) {
        startDate = startDate.add(5 - remainder, 'minutes') // 调整到下一个5分钟点
    }
    startDate.second(0) // 将秒设置为0
    startDate.millisecond(0) // 将毫秒设置为0

    while (startDate.isSameOrBefore(endDate)) {
        timestamps.push({ time: startDate.valueOf() })
        startDate.add(value, unit)
    }

    return timestamps
}

//生成時間陣列
const generateTimeArr = (start, end, timescale) => {
    const timestamps = []
    let startDate = moment(start, 'YYYY/MM/DD HH:mm:ss')
    const endDate = moment(end, 'YYYY/MM/DD HH:mm:ss')
    const { unit, value } = timeScales(timescale) || timeScales('default') // default to 'default'

    // 调整开始时间到下一个整点、15分、30分或45分
    if (timescale === 'quarter') {
        const minutes = startDate.minute()
        if (minutes > 45) {
            startDate = startDate.add(60 - minutes, 'minutes') // 下一个整点
        } else if (minutes > 30) {
            startDate = startDate.add(45 - minutes, 'minutes') // 下一个45分
        } else if (minutes > 15) {
            startDate = startDate.add(30 - minutes, 'minutes') // 下一个30分
        } else if (minutes > 0) {
            startDate = startDate.add(15 - minutes, 'minutes') // 下一个15分
        }
        startDate.second(0) // 将秒设置为0
        startDate.millisecond(0) // 将毫秒设置为0
    }

    while (startDate.isSameOrBefore(endDate)) {
        timestamps.push({ time: startDate.valueOf() })
        startDate.add(value, unit)
    }

    return timestamps
}

//合併圖表資料
export const mergeCharts = (arr = [], start, end, timescale, attr) => {
    const timeValueMap = arr.reduce(
        (acc, cur) => ({ ...acc, [cur.time]: cur }),
        {}
    )

    const newArr = generateTimeArr(start, end, timescale)
    // const currentTime = moment()

    newArr.forEach((item) => {
        let key = item.time
        const obj = timeValueMap[key] // 使用时间戳作为key

        // if (moment(key).isAfter(currentTime)) {
        //     item[attr] = null
        // } else {
        //     if (obj) {
        //         if (Array.isArray(attr)) {
        //             attr.forEach((attribute) => {
        //                 item[attribute] = obj[attribute]
        //             })
        //         } else {
        //             item[attr] = obj[attr]
        //         }
        //     }
        // }

        if (obj) {
            if (Array.isArray(attr)) {
                attr.forEach((attribute) => {
                    item[attribute] = obj[attribute]
                })
            } else {
                item[attr] = obj[attr]
            }
        }
    })

    return newArr
}

//合併2個資料
export const merge2Charts = (
    arr1 = [],
    arr2 = [],
    name1,
    name2,
    start,
    end,
    timescale
) => {
    const timeValueMap1 = arr1?.reduce(
        (acc, cur) => ({ ...acc, [cur.time]: cur }),
        {}
    )
    const timeValueMap2 = arr2?.reduce(
        (acc, cur) => ({ ...acc, [cur.time]: cur }),
        {}
    )
    const newArr = generateTimeArr(start, end, timescale)
    // const currentTime = moment()

    newArr.forEach((item) => {
        let key = item.time
        const obj1 = timeValueMap1?.[key] // 使用时间戳作为key
        const obj2 = timeValueMap2?.[key] // 使用时间戳作为key

        // if (moment(key).isAfter(currentTime)) {
        //     item[name1] = null
        //     item[name2] =
        //         timescale === 'quarter'
        //             ? parseFloat(obj2?.power?.toFixed(2))
        //             : parseFloat(obj2?.value?.toFixed(2))
        // } else {
        //     if (obj1) {
        //         item[name1] =
        //             timescale === 'quarter'
        //                 ? parseFloat(obj1.power?.toFixed(2))
        //                 : parseFloat(obj1.value?.toFixed(2))
        //     }
        //     if (obj2) {
        //         item[name2] =
        //             timescale === 'quarter'
        //                 ? parseFloat(obj2.power?.toFixed(2))
        //                 : parseFloat(obj2.value?.toFixed(2))
        //     }
        // }

        if (obj1) {
            item[name1] =
                timescale === 'quarter'
                    ? parseFloat(obj1.power?.toFixed(2))
                    : parseFloat(obj1.value?.toFixed(2))
        }
        if (obj2) {
            item[name2] =
                timescale === 'quarter'
                    ? parseFloat(obj2.power?.toFixed(2))
                    : parseFloat(obj2.value?.toFixed(2))
        }
    })

    return newArr
}

export const merge2EssCharts = (
    arr1 = [],
    arr2 = [],
    name1,
    name2,
    start,
    end,
    timescale
) => {
    const newArr = generateTimeArr(start, end, timescale)

    const mapData = (arr, name) =>
        arr
            ? arr.reduce((acc, cur) => {
                  acc[cur.time] = {
                      ...acc[cur.time],
                      [name]: parseFloat(cur?.power?.toFixed(2)),
                      chargeValue: cur?.chargeValue,
                      dischargeValue: Math.abs(cur?.dischargeValue),
                      cumulationChargeValue: cur?.cumulationChargeValue,
                      cumulationDischargeValue: Math.abs(
                          cur?.cumulationDischargeValue
                      ),
                  }
                  if (cur?.soc !== undefined) {
                      acc[cur.time].soc = cur?.soc
                  }
                  return acc
              }, {})
            : {}

    const dataMap = { ...mapData(arr1, name1), ...mapData(arr2, name2) }

    newArr.forEach((item, index) => {
        if (dataMap[item.time]) {
            newArr[index] = { ...item, ...dataMap[item.time] }
        }
    })

    return newArr
}

export const mergeAlertEssCharts = (arr = [], start, end) => {
    const newArr = generateTimeArrOfFiveMinutes(start, end)
    const mapData = (arr) =>
        arr
            ? arr.reduce((acc, cur) => {
                  acc[cur.time] = {
                      ...acc[cur.time],
                      monitorSOC: cur?.essLastSOC,
                      expectedSOC: cur?.expectedSOC,
                  }
                  return acc
              }, {})
            : {}

    const dataMap = mapData(arr)

    newArr.forEach((item, index) => {
        if (dataMap[item.time]) {
            newArr[index] = { ...item, ...dataMap[item.time] }
        }
    })

    return newArr
}

export const mergeAlertEssCharts2 = (arr = [], start, end) => {
    const newArr = generateTimeArrOfFiveMinutes(start, end)
    const mapData = (arr) =>
        arr
            ? arr.reduce((acc, cur) => {
                  acc[cur.time] = {
                      ...acc[cur.time],
                      soc: cur?.soc,
                  }
                  return acc
              }, {})
            : {}

    const dataMap = mapData(arr)

    newArr.forEach((item, index) => {
        if (dataMap[item.time]) {
            newArr[index] = { ...item, ...dataMap[item.time] }
        }
    })

    return newArr
}

const processData = (newArr, data, propertyName, timescale) => {
    // const currentTime = moment()

    if (!data || data.length === 0) {
        // Do nothing if data is undefined, null, or an empty array
        return newArr
    }

    for (let d of data) {
        for (let item of newArr) {
            if (item.time === d?.time) {
                if (propertyName === 'demandLimit') {
                    item.demandLimit = parseFloat(d?.demandLimit?.toFixed(2))
                    item.allowedPower = parseFloat(d?.allowedPower?.toFixed(2))
                } else if (propertyName === 'grayMonitor') {
                    // if (!moment(item.time).isAfter(currentTime)) {
                        item[propertyName] =
                            timescale === 'quarter'
                                ? parseFloat(d?.gridPower?.toFixed(2))
                                : parseFloat(d?.gridEnergy?.toFixed(2))
                    // }
                } else if (propertyName === 'wheelMonitor') {
                    // if (!moment(item.time).isAfter(currentTime)) {
                        item[propertyName] =
                            timescale === 'quarter'
                                ? parseFloat(d?.wheelingPower?.toFixed(2))
                                : parseFloat(d?.wheelingEnergy?.toFixed(2))
                    // }
                } else if (propertyName === 'wheelPvMonitor') {
                    // if (!moment(item.time).isAfter(currentTime)) {
                        item[propertyName] =
                            timescale === 'quarter'
                                ? parseFloat(d?.wheelingPvPower?.toFixed(2))
                                : parseFloat(d?.wheelingPvEnergy?.toFixed(2))
                    // }
                } else {
                    // if (!moment(item.time).isAfter(currentTime)) {
                        item[propertyName] =
                            timescale === 'quarter'
                                ? parseFloat(d?.power?.toFixed(2))
                                : parseFloat(d?.value?.toFixed(2))
                    // }
                }
            }
        }
    }
    return newArr
}

//合成主chart
export const mergeMainChart = ({
    contractDemandLimit,
    gray,
    pv,
    load,
    ess,
    evse,
    wind,
    wheel,
    wheelPv,
    start,
    end,
    timescale,
}) => {
    let newArr = generateTimeArr(start, end, timescale)
    newArr = processData(newArr, contractDemandLimit, 'demandLimit', timescale)
    newArr = processData(newArr, wheel, 'wheelMonitor', timescale)
    newArr = processData(newArr, gray, 'grayMonitor', timescale)
    newArr = processData(newArr, wheelPv, 'wheelPvMonitor', timescale)
    newArr = processData(newArr, pv, 'pvMonitor', timescale)
    newArr = processData(newArr, load, 'loadMonitor', timescale)
    newArr = processData(newArr, evse, 'evseMonitor', timescale)
    newArr = processData(newArr, wind, 'windMonitor', timescale)

    if (ess) {
        for (let e of ess) {
            for (let item of newArr) {
                if (item.time === e?.time) {
                    // if (moment(item.time).isAfter(moment())) {
                    //     item.essMonitor = null
                    //     item.chargeValue = null
                    //     item.dischargeValue = null
                    //     item.soc = null
                    // } else {
                    //     item.essMonitor =
                    //         timescale === 'quarter'
                    //             ? parseFloat(e?.power?.toFixed(2))
                    //             : parseFloat(e?.value?.toFixed(2))
                    //     item.chargeValue = parseFloat(e?.chargeValue)
                    //     item.dischargeValue = Math.abs(
                    //         parseFloat(e?.dischargeValue)
                    //     )
                    //     item.soc = e?.soc
                    // }

                    item.essMonitor =
                            timescale === 'quarter'
                                ? parseFloat(e?.power?.toFixed(2))
                                : parseFloat(e?.value?.toFixed(2))
                    item.chargeValue = parseFloat(e?.chargeValue)
                    item.dischargeValue = Math.abs(
                        parseFloat(e?.dischargeValue)
                    )
                    item.soc = e?.soc
                }
            }
        }
    }

    return newArr
}

//合成台電含綠電轉供
export const mergeTaiPowerChart = ({ gray, wheel, start, end, timescale }) => {
    let newArr = generateTimeArr(start, end, timescale)
    newArr = processData(newArr, wheel, 'wheelMonitor', timescale)
    newArr = processData(newArr, gray, 'grayMonitor', timescale)
    return newArr
}

export const calcRatio = ({ type, prev_type, key }) => {
    // 解构参数并检查它们
    const hasData = type && prev_type && type.length > 0 && prev_type.length > 0
    if (!hasData) {
        return null
    }

    // 获取最新的值
    const currentValue = type[type.length - 1][key]
    const previousValue = prev_type[prev_type.length - 1][key]

    // 检查分母是否为0或null
    if (!previousValue) {
        return null
    }

    // 计算比率
    const ratio = ((currentValue - previousValue) / previousValue) * 100

    // 返回格式化的比率
    return ratio.toFixed(2)
}

export const calcRatio_both = ({
    type1,
    prev_type1,
    type2,
    prev_type2,
    key,
}) => {
    if (type1 && prev_type1 && type1.length !== 0 && prev_type1.length !== 0) {
        let val1 = type1[type1.length - 1]?.[key] || 0
        let val2 = prev_type1[prev_type1.length - 1]?.[key] || 0

        if (
            type2 &&
            prev_type2 &&
            type2.length !== 0 &&
            prev_type2.length !== 0
        ) {
            val1 += type2[type2.length - 1]?.[key] || 0
            val2 += prev_type2[prev_type2.length - 1]?.[key] || 0
        }

        const ratio = ((val1 - val2) / val2) * 100
        return ratio.toFixed(2)
    }

    return null
}

// 計算node裡的值
export const calcVal = ({
    type,
    attr = 'power',
    refLine,
    end = false,
    date = moment(),
    noToFixed = false, //false為到小數第二位，true為原數據
}) => {
    if (!type || type.length === 0)
        return attr === 'chargePointStatus' ? null : 0 // 没有抓到数据，返回0
    if (refLine?.active) {
        if (attr !== 'power') {
            //沒資料且不是瞬時功率取最接近時間點的累積度數
            const filteredData = type?.filter(
                (item) => new Date(item.time).getTime() <= refLine.payload?.time
            )
            if (filteredData.length === 0) {
                return attr === 'chargePointStatus' ? null : 0 // 如果沒有找到任何在指定時間之前的數據
            }
            // 找到最接近的時間點的數據
            let closestData = filteredData[0]
            let closestTimeDiff =
                refLine.payload?.time - new Date(closestData.time).getTime()

            for (let i = 1; i < filteredData.length; i++) {
                const currentTimeDiff =
                    refLine.payload?.time -
                    new Date(filteredData[i].time).getTime()
                if (currentTimeDiff < closestTimeDiff) {
                    closestData = filteredData[i]
                    closestTimeDiff = currentTimeDiff
                }
            }
            if (noToFixed)
                return attr === 'chargePointStatus'
                    ? closestData[attr]
                    : Number(closestData[attr])
            return attr === 'chargePointStatus'
                ? closestData[attr]
                : Number(closestData[attr]?.toFixed(2) || 0)
        }
        //找瞬時的功率資料
        const ref = type?.filter(
            (item) =>
                item['time'] == refLine.payload?.time ||
                Date.parse(item?.['time']?.replace?.(/-/g, '/')) ==
                    refLine.payload?.time
        )
        if (ref.length !== 0 && ref[0][attr]) {
            if (noToFixed) return Number(ref[0][attr])
            return Number(ref[0][attr]?.toFixed(2) || 0)
        } else {
            return 0
        }
    } else {
        if (end) {
            if (attr === 'chargePointStatus') return type[type.length - 1][attr]
            if (noToFixed) return Number(type[type.length - 1][attr] || 0)
            return Number(type[type.length - 1][attr]?.toFixed(2) || 0)
        }
        if (!date.isSame(moment(), 'day')) {
            // 如果不是今天，則找到那天結尾時間的15分鐘内的物件
            const timeThreshold = date
                .clone()
                .endOf('day')
                .add(1, 'seconds')
                .subtract(15, 'minutes') // 使用 clone 方法来创建一个新的 moment 对象
            const itemInTimeRange = type.find((item) =>
                moment(item['time']).isBetween(
                    timeThreshold,
                    date.clone().endOf('day').add(1, 'seconds')
                )
            )
            if (attr === 'chargePointStatus') return itemInTimeRange[attr]
            // 如果找到了符合条件的物件，返回其指定属性的值，否则返回0
            if (noToFixed)
                return itemInTimeRange ? Number(itemInTimeRange[attr] || 0) : 0
            return itemInTimeRange
                ? Number(itemInTimeRange[attr]?.toFixed(2) || 0)
                : 0
        }
        // 找到現在時間15分鐘内的物件
        const timeThreshold = moment().subtract(30, 'minutes')
        const itemInTimeRange = type.find((item) =>
            moment(item['time']).isBetween(timeThreshold, moment())
        )

        if (attr === 'chargePointStatus') return itemInTimeRange[attr]

        // 如果找到了符合条件的物件，返回其指定属性的值，否则返回0
        if (noToFixed)
            return itemInTimeRange ? Number(itemInTimeRange[attr] || 0) : 0
        return itemInTimeRange
            ? Number(itemInTimeRange[attr]?.toFixed(2) || 0)
            : 0
    }
}

//計算node裡的time
export const calcTime = ({ type, date }) => {
    if (!type || type.length === 0) return 0 // 没有抓到数据，返回0

    if (!moment().isSame(date, 'day')) {
        return type[type.length - 1]?.time
    }

    // 找到现在时间15分钟内的物件
    const timeThreshold = moment().subtract(30, 'minutes')
    const itemInTimeRange = type.find((item) =>
        moment(item['time']).isBetween(timeThreshold, moment())
    )
    return itemInTimeRange ? itemInTimeRange.time : moment()
}

//計算node裡ess的soc
export const calcSOC = (type) => {
    if (!type || type.length === 0) return 0 // 没有抓到数据，返回0

    // 找到现在时间15分钟内的物件
    const timeThreshold = moment().subtract(15, 'minutes')
    const itemInTimeRange = type.find((item) =>
        moment(item['time']).isBetween(timeThreshold, moment())
    )

    // 如果找到了符合条件的物件，返回其power值，否则返回0
    return itemInTimeRange ? Number(itemInTimeRange.soc?.toFixed(2)) || 0 : 0
}

//計算線稿的最低值
export const minNum = (arr, ...keys) => {
    if (!arr || arr.length === 0 || keys.length === 0) {
        return 0
    }
    const minValues = keys.map((key) =>
        Math.min(
            ...arr.map((item) =>
                item[key] !== undefined ? item[key] : Infinity
            )
        )
    )
    const min = Math.min(...minValues)
    return min < 0 ? Math.floor(min) : Math.ceil(min)
}

// 閉包含數
;(function () {
    /**
     * Decimal adjustment of a number.
     *
     * @param {String}  type  The type of adjustment.
     * @param {Number}  value The number.
     * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
     * @returns {Number} The adjusted value.
     */
    function decimalAdjust(type, value, exp) {
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value)
        }
        value = +value
        exp = +exp
        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN
        }
        // Shift
        value = value.toString().split('e')
        value = Math[type](
            +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp))
        )
        // Shift back
        value = value.toString().split('e')
        return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
    }

    // Decimal round
    if (!Math.round10) {
        Math.round10 = function (value, exp) {
            return decimalAdjust('round', value, exp)
        }
    }
    // Decimal floor
    if (!Math.floor10) {
        Math.floor10 = function (value, exp) {
            return decimalAdjust('floor', value, exp)
        }
    }
    // Decimal ceil
    if (!Math.ceil10) {
        Math.ceil10 = function (value, exp) {
            return decimalAdjust('ceil', value, exp)
        }
    }
})()

// legend & tooltip 文字ma
const valueToLabelMap = {
    demandLimit: '契約容量',
    allowedPower: '超標後的調度基準線',
    pvMonitor: '太陽能自發自用',
    loadMonitor: '負載',
    essMonitor: '儲能',
    pvPredict: '太陽能預測',
    loadPredict: '負載預測',
    essPredict: '調度目標',
    grayMonitor: '台電',
    cost: '時間電價',
    windMonitor: '風能',
    windPredict: '風能預測',
    evseMonitor: '充電樁',
    soc: 'SOC',
    monitorSOC: '實際的SOC',
    expectedSOC: '預測的SOC',
    chargeValue: '放電',
    dischargeValue: '充電',
    actual: '實際電費',
    economize: '節省費用',
    savefine: '契約容量節省',
    selfsavebill: '綠能節省',
    savebill: '電費節省',
    essFitIncome: '儲能節省',
    carbonReduction: '碳排量節省',
    before: '原始電費',
    after: '調度後電費',
    wheelMonitor: '綠電轉供',
    wheelPvMonitor: '轉供案場發電',
    usedPower: '用電量',
    usedGreen: '綠電使用量',
}

// legend文字format
export const renderColorfulLegendText = (value, opacity = false) => {
    const label = valueToLabelMap[value] || '未定義'
    if (opacity === false)
        return <span className="recharts-legend-text">{label}</span>
    return (
        <span
            className={`recharts-legend-text ${
                opacity[value] ? '' : 'opacity'
            }`}
        >
            {label}
        </span>
    )
}

// labelFormatter
export const labelFormatter = (val, timescale) => {
    const date = moment(val)
    switch (timescale) {
        case 'fiveMinutes':
            return date.format('HH:mm')
        case 'quarter':
            return date.format('HH:mm')
        case 'monthly':
            return date.format('YYYY-MM')
        case 'yearly':
            return date.format('YYYY')
        default:
            return date.format('YYYY-MM-DD')
    }
}

// renderTipFormatter
export const renderTipFormatter = (value, name, timescale) => {
    if (isNaN(value)) value = 0
    value = Number(value)
    let unit =
        timescale === 'quarter' || timescale === 'fiveMinutes' ? ' kW' : ' kWh'
    if (
        name === 'actual' ||
        name === 'economize' ||
        name === 'before' ||
        name === 'after' ||
        name === 'savefine' ||
        name === 'savebill' ||
        name === 'selfsavebill' ||
        name === 'essFitIncome'
    )
        unit = ' NTD'
    if (name === 'carbonReduction') unit = ' kg'
    let displayValue =
        name === 'soc' || name === 'monitorSOC' || name === 'expectedSOC'
            ? Number((value * 100)?.toFixed(2)) + ' %'
            : Number(value.toFixed(2).toLocaleString('en-US')) + unit

    return [displayValue, valueToLabelMap[name] || '未定義']
}

//tickFormatter
export const tickFormatter = (num, ticklength, datalength, timescale) => {
    const date = moment(num)
    switch (timescale) {
        case 'fiveMinutes':
            if (datalength > 288) {
                return date.format('MM-DD')
            } else {
                return date.format('HH:mm')
            }
        case 'quarter':
            if (datalength > 97) {
                return date.format('MM-DD')
            } else {
                return date.format('HH:mm')
            }
        case 'monthly':
            return date.format('MM')
        case 'yearly':
            return date.format('YYYY')
        default:
            return date.format('MM-DD')
    }
}

// 計算契約容量(當前負載最高值)
export const maxTick = (load, time) => {
    if (load) {
        const data = load.filter((item, index) => {
            return index <= time
        })
        const maxTick = data.reduce((pre, cur) => {
            return Math.max(pre, cur.powerKW).toFixed(2)
        }, 0)
        return maxTick
    }
}

// loading spin
export const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 55,
        }}
        spin
    />
)

// menu
export const menu = (obj) => {
    const arr = [
        {
            key: '/',
            icon: <HomeOutlined />,
            label: <Link to="/">首頁/儀表板</Link>,
            name: '首頁/儀表板',
            path: '/',
        },
        {
            key: '/device',
            icon: <ClusterOutlined />,
            label: <Link to="/device">能源設備總覽</Link>,
            name: '能源設備總覽',
            path: '/device',
        },
        {
            key: '/analysis',
            icon: <FileSearchOutlined />,
            label: <Link to="/analysis">分析報告</Link>,
            name: '分析報告',
            path: '/analysis',
        },
        {
            key: '/contractconfig',
            icon: <ReconciliationOutlined />,
            label: <Link to="/contractconfig">契約容量管理</Link>,
            name: '契約容量管理',
            path: '/contractconfig',
        },
        {
            key: '/greenenergyreport',
            icon: <FileDoneOutlined />,
            label: <Link to="/greenenergyreport">綠電資訊報表</Link>,
            name: '綠電資訊報表',
            path: '/greenenergyreport',
        },
        {
            key: '/abnormal',
            icon: (
                <Badge count={obj?.alert || null} size="small">
                    <ToolOutlined />
                </Badge>
            ),
            label: (
                <Link to="/abnormal?expired=false">
                    <Badge
                        count={obj?.alert || null}
                        size="small"
                        offset={[16, 7]}
                    >
                        異常檢測
                    </Badge>
                </Link>
            ),
            name: '異常檢測',
            path: '/abnormal',
        },
        {
            key: '/settings',
            icon: <SettingOutlined />,
            label: <Link to="/settings">系統設定</Link>,
            name: '系統設定',
            path: '/settings',
        },
    ]
    if (obj?.customTimeLineMode)
        arr.splice(1, 0, {
            key: '/timelinedashboard',
            icon: <PlayCircleOutlined />,
            label: <Link to="/timelinedashboard">模擬能源調度</Link>,
            name: '模擬能源調度',
            path: '/timelinedashboard',
        })
    return arr
}

//路由
export const routeList = [
    {
        path: '/',
        breadcrumbName: '首頁',
    },
    {
        path: '/timelinedasboard',
        breadcrumbName: '模擬能源流動',
    },
    {
        path: '/device',
        breadcrumbName: '能源設備總覽',
    },
    {
        path: '/device/:id',
        breadcrumbName: '能源設備總覽',
    },
    {
        path: '/abnormal',
        breadcrumbName: '異常檢測',
    },
    {
        path: '/abnormal/:id',
        breadcrumbName: '異常檢測',
    },
    {
        path: '/alert',
        breadcrumbName: '警告列表',
    },
    {
        path: '/analysis',
        breadcrumbName: '分析報告',
    },
    {
        path: '/contractconfig',
        breadcrumbName: '契約容量管理',
    },
    {
        path: '/contractconfig/:id',
        breadcrumbName: '契約容量管理',
    },
    {
        path: '/greenenergyreport',
        breadcrumbName: '綠電資訊報表',
    },
    {
        path: '/settings',
        breadcrumbName: '系統設定',
    },
    {
        path: '/changepassword',
        breadcrumbName: '重設密碼',
    },
]

//時間下拉選單
export const selectTimeMenu = [
    {
        value: 'today',
        label: '今天',
    },
    {
        value: 'yesterday',
        label: '昨天',
    },
    {
        value: 'week',
        label: '最近7天',
    },
    {
        value: 'thisMonth',
        label: '本月至今',
    },
    {
        value: 'thirtyDays',
        label: '最近30天',
    },
    {
        value: 'prevMonth',
        label: '前一個月',
    },
]

//時間下拉選單2
export const selectTimeMenu2 = [
    {
        value: 'thisMonth',
        label: '本月至今',
    },
    {
        value: 'prevMonth',
        label: '上一個月',
    },
    {
        value: 'thisYear',
        label: '今年至今',
    },
    {
        value: 'twelveMonth',
        label: '最近一年',
    },
    {
        value: 'prevYear',
        label: '去年',
    },
]

//時間下拉選單3
export const selectTimeMenu3 = [
    {
        value: 'thisYear',
        label: '今年至今',
    },
    {
        value: 'twelveMonth',
        label: '最近一年',
    },
    {
        value: 'prevYear',
        label: '去年',
    },
]

// X軸時間點
export const timeArr = [
    15, 120, 240, 360, 480, 600, 720, 840, 960, 1080, 1200, 1320, 1440,
]

//時間電價時間點
export const timeCostArr = [450, 600, 720, 780, 1020, 1350]

//抓取token
export const getToken = () => {
    return GetDecryptedString(window.localStorage.getItem('token'))
}

//抓取refreshToken
export const getRefreshToken = () => {
    return GetDecryptedString(window.localStorage.getItem('refreshToken'))
}

//fetch
export const fetchData = async ({ queryKey, query }) => {
    let startDatetime, endDatetime
    if (queryKey[1].startDatetime && queryKey[1].endDatetime) {
        startDatetime = queryKey[1].startDatetime
        endDatetime = queryKey[1].endDatetime
    } else {
        const obj = getDateTimeRange(
            queryKey[1].dateline,
            queryKey[1].date,
            queryKey[1].usePreviousPeriod,
            queryKey[1].endOfThatDay,
            queryKey[1].demo,
        )
        startDatetime = obj.startDatetime
        endDatetime = obj.endDatetime
    }
    
    try {
        const response = await axios({
            method: 'POST',
            // eslint-disable-next-line no-undef
            url: process.env.REACT_APP_API,
            headers: {
                Authorization: getToken(),
            },
            data: {
                query: query,
                variables: {
                    id: queryKey[1].id,
                    timezone: queryKey[1].timezone,
                    timescale: queryKey[1].timescale,
                    startDatetime,
                    endDatetime,
                    ...queryKey[1].variables, // optional: for additional variables
                },
            },
        })
        return response.data.data // Response received from the API
    } catch (error) {
        return await Promise.reject(error)
    }
}

//query
export const getGraphql = (auth, query, variables, dateline, date) => {
    let dateVariables = {}
    if (dateline && date) {
        const { startDatetime, endDatetime } = getDateTimeRange(dateline, date)
        dateVariables = { startDatetime, endDatetime }
    }

    const options = {
        method: 'POST',
        // eslint-disable-next-line no-undef
        url: process.env.REACT_APP_API,
        headers: {
            Authorization: auth,
        },
        data: {
            query,
            variables: { ...variables, ...dateVariables },
        },
    }

    const res = axios
        .request(options)
        .then(function (response) {
            const res = response.data.data // Response received from the API
            return res
        })
        .catch(function (error) {
            return Promise.reject(error)
        })

    return res
}

//讀取上一個月
const getLastMonth = ({ date, prev, end }) => {
    let currentMonth = date.getMonth()
    let currentYear = date.getFullYear()
    let prevMonth = currentMonth === 0 ? 11 : currentMonth - 1
    if (prevMonth === 11) currentYear--
    if (prev) {
        prevMonth--
        if (end) {
            return new Date(currentYear, prevMonth + 1, 0)
        } else {
            return new Date(currentYear, prevMonth, 1)
        }
    } else {
        if (end) {
            return new Date(currentYear, prevMonth + 1, 0)
        } else {
            return new Date(currentYear, prevMonth, 1)
        }
    }
}

//讀取本月
const getThisMonth = ({ date, prev, end }) => {
    let currentMonth = date.getMonth()
    let currentYear = date.getFullYear()
    if (prev) {
        let prevMonth = currentMonth === 0 ? 11 : currentMonth - 1
        if (prevMonth === 11) currentYear--
        if (end) {
            return new Date(
                currentYear,
                prevMonth,
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getUTCSeconds()
            )
        } else {
            return new Date(currentYear, prevMonth, 1)
        }
    } else {
        if (end) {
            return new Date(
                currentYear,
                currentMonth,
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getUTCSeconds()
            )
        } else {
            return new Date(currentYear, currentMonth, 1)
        }
    }
}

//讀取今年至今
const getThisYear = ({ date, prev, end }) => {
    let year = date.getFullYear()
    if (prev) {
        year--
        if (end) {
            return new Date(
                year,
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getUTCSeconds()
            )
        } else {
            return new Date(year, 3, 1)
        }
    } else {
        if (end) {
            return new Date(
                year,
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getUTCSeconds()
            )
        } else {
            return new Date(year, 3, 1)
        }
    }
}

//讀取近12個月
const getTwelveMonth = ({ date, prev, end }) => {
    let year = date.getFullYear()
    let month = date.getMonth() // 減去11個月，因為getMonth()從0開始計算
    if (prev) {
        if (end) {
            return new Date(
                year - 1,
                month,
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getUTCSeconds()
            )
        } else {
            month -= 11
            if (month < 0) {
                month += 12
                year--
            }
            return new Date(year - 1, month, 1)
        }
    } else {
        if (end) {
            return new Date(
                year,
                month,
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getUTCSeconds()
            )
        } else {
            month -= 11
            if (month < 0) {
                month += 12
                year--
            }
            return new Date(year, month, 1)
        }
    }
}

//讀取去年
const getPrevYear = ({ prev, end }) => {
    if (prev) {
        if (end) {
            return new Date(2021, 3, 30, 23, 59, 59)
        } else {
            return new Date(2021, 0, 1)
        }
    } else {
        if (end) {
            return new Date(2022, 3, 30, 23, 59, 59)
        } else {
            return new Date(2022, 0, 1)
        }
    }
}

//計算起始時間
export const computeStartTime = ({ dateline, option, prev, now }) => {
    let date = new Date()
    if (now !== undefined) date = new Date()
    if (option && option !== '') date = new Date(`${option} 00:00:00`)
    if (dateline === 'today') date.setDate(date.getDate() - (prev ? 1 : 0))
    if (dateline === 'yesterday') date.setDate(date.getDate() - (prev ? 2 : 1))
    if (dateline === 'week') date.setDate(date.getDate() - (prev ? 13 : 6))
    if (dateline === 'thisMonth')
        date = getThisMonth({ date, prev, end: false })
    if (dateline === 'thirtyDays')
        date.setDate(date.getDate() - (prev ? 59 : 29))
    if (dateline === 'prevMonth')
        date = getLastMonth({ date, prev, end: false })
    if (dateline === 'thisYear') date = getThisYear({ date, prev, end: false })
    if (dateline === 'twelveMonth')
        date = getTwelveMonth({ date, prev, end: false })
    if (dateline === 'prevYear') date = getPrevYear({ date, prev, end: false })
    return `${date.getFullYear()}/${
        date.getMonth() + 1
    }/${date.getDate()} 00:00:00`
}

//計算結束時間
export const computeEndTime = ({ dateline, option, predict, prev, now }) => {
    let date = new Date()
    if (now !== undefined) date = new Date()
    if (option && option !== '')
        date = new Date(
            `${option} ${date.getHours()}:${date.getMinutes()}:${date.getUTCSeconds()}`
        )
    if (dateline === 'today') date.setDate(date.getDate() - (prev ? 1 : 0))
    if (dateline === 'yesterday') date.setDate(date.getDate() - (prev ? 2 : 1))
    if (dateline === 'week') date.setDate(date.getDate() - (prev ? 7 : 0))
    if (dateline === 'thisMonth') date = getThisMonth({ date, prev, end: true })
    if (dateline === 'thirtyDays')
        date.setDate(date.getDate() - (prev ? 30 : 0))
    if (dateline === 'prevMonth') date = getLastMonth({ date, prev, end: true })
    if (dateline === 'thisYear') date = getThisYear({ date, prev, end: true })
    if (dateline === 'twelveMonth')
        date = getTwelveMonth({ date, prev, end: true })
    if (dateline === 'prevYear') date = getPrevYear({ date, prev, end: true })
    if (predict === 'predict' || dateline === 'yesterday') {
        return `${date.getFullYear()}/${
            date.getMonth() + 1
        }/${date.getDate()} 23:59:59`
    }
    return `${date.getFullYear()}/${
        date.getMonth() + 1
    }/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getUTCSeconds()}`
}

//毫秒轉換時間
export const transTime = (val, timescale) => {
    let date = new Date(val)
    if (timescale === 'daily')
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    return `${date.getFullYear()}-${
        date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getUTCSeconds()}`
}

//
export const defaultLayouts = {
    lg: [
        { x: 0, y: 0, w: 1, h: 1, i: 'energyControl' },
        { x: 1, y: 0, w: 1, h: 1, i: 'loadMonitor' },
        { x: 2, y: 0, w: 1, h: 1, i: 'solarMonitor' },
        { x: 0, y: 0, w: 1, h: 1, i: 'essMonitor' },
        { x: 1, y: 0, w: 1, h: 1, i: 'evseMonitor' },
        { x: 2, y: 0, w: 1, h: 1, i: 'windMonitor' },
        { x: 0, y: 0, w: 1, h: 1, i: 'greenBenefits' },
        { x: 1, y: 0, w: 1, h: 1, i: 'essBenefits' },
        { x: 2, y: 0, w: 1, h: 1, i: 'carbonEmissions' },
        { x: 0, y: 0, w: 1, h: 1, i: 'economyEstimate' },
        { x: 1, y: 0, w: 1, h: 1, i: 'energyReview' },
        { x: 2, y: 0, w: 1, h: 1, i: 'contractStatus' },
        { x: 0, y: 0, w: 1, h: 1, i: 'energyTarget' },
    ],
}

//主面板上方初始layout
export const defaultLayouts1 = {
    lg: [
        { x: 0, y: 0, w: 4, h: 1, i: '1', minW: 4 },
        { x: 4, y: 0, w: 4, h: 1, i: '2', minW: 4 },
        { x: 8, y: 0, w: 4, h: 1, i: '3', minW: 4 },
        { x: 0, y: 2, w: 8, h: 2, i: '4', minW: 4, minH: 2 },
        { x: 0, y: 10, w: 8, h: 3, i: '5', isResizable: false },
        { x: 8, y: 2, w: 4, h: 1, i: '6', minW: 2 },
        { x: 8, y: 2, w: 4, h: 1, i: '7', minW: 2 },
        { x: 8, y: 2, w: 4, h: 1, i: '8', minW: 2 },
        { x: 8, y: 2, w: 4, h: 1, i: '9', minW: 2 },
        { x: 8, y: 2, w: 4, h: 1, i: '10', minW: 2 },
    ],
    md: [
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: '1',
        },
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: '2',
        },
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: '3',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 2,
            i: '4',
        },
        {
            x: 0,
            y: 10,
            w: 4,
            h: 3,
            i: '5',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '6',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '7',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '8',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '9',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '10',
        },
    ],
    xs: [
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: '1',
        },
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: '2',
        },
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: '3',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 2,
            i: '4',
        },
        {
            x: 0,
            y: 10,
            w: 4,
            h: 3,
            i: '5',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '6',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '7',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '8',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '9',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 1,
            i: '10',
        },
    ],
}

//主面板下方初始layout
export const defaultLayouts2 = {
    lg: [
        { x: 0, y: 0, w: 6, h: 1, i: '1', minW: 6 },
        { x: 6, y: 0, w: 6, h: 1, i: '2', minW: 6 },
        { x: 0, y: 2, w: 8, h: 2, i: '3', minW: 6, minH: 2 },
        { x: 8, y: 2, w: 4, h: 1, i: '4', minW: 4 },
        { x: 8, y: 2, w: 4, h: 1, i: '5', minW: 4 },
    ],
    md: [
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: '1',
        },
        {
            x: 0,
            y: 1,
            w: 4,
            h: 1,
            i: '2',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 2,
            i: '3',
        },
        {
            x: 0,
            y: 3,
            w: 4,
            h: 1,
            i: '4',
        },
        {
            x: 0,
            y: 4,
            w: 4,
            h: 1,
            i: '5',
        },
    ],
    xs: [
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: '1',
        },
        {
            x: 0,
            y: 1,
            w: 4,
            h: 1,
            i: '2',
        },
        {
            x: 0,
            y: 2,
            w: 4,
            h: 2,
            i: '3',
        },
        {
            x: 0,
            y: 3,
            w: 4,
            h: 1,
            i: '4',
        },
        {
            x: 0,
            y: 4,
            w: 4,
            h: 1,
            i: '5',
        },
    ],
}

//主面板layout從localStorage得到
export function getFromLS(type) {
    let ls = {}
    if (window.localStorage) {
        if (type === 1) {
            ls = JSON.parse(window.localStorage.getItem('layouts-1'))
        } else {
            ls = JSON.parse(window.localStorage.getItem('layouts-2'))
        }
    }
    return ls
}

//儲存主面板layout至localStorage
export function saveToLS(value, type) {
    if (type === 1) {
        window.localStorage.setItem('layouts-1', JSON.stringify(value))
    } else {
        window.localStorage.setItem('layouts-2', JSON.stringify(value))
    }
}

//計算月份或年度
export const countDateline = (dateline) => {
    if (dateline === 'thisMonth' || dateline === 'prevMonth') {
        return 'monthly'
    } else {
        return 'yearly'
    }
}

//麵包屑計算路由
export const itemRender = (route, params, routes) => {
    const last = routes.indexOf(route) === routes.length - 1
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={route.path}>{route.breadcrumbName}</Link>
    )
}

export const getTimelinePeriod = (timeline = 'day') => {
    if (timeline === 'day' || timeline === 'week') {
        return 'quarter'
    } else if (timeline === 'month') {
        return 'daily'
    } else if (timeline === 'year') {
        return 'monthly'
    } else {
        return 'yearly'
    }
}

export const getTimelinePeriodForTarget = (timeline = 'day') => {
    if (timeline === 'day' || timeline === 'week') {
        return 'daily'
    } else if (timeline === 'month') {
        return 'monthly'
    } else if (timeline === 'year') {
        return 'yearly'
    } else {
        return 'yearly'
    }
}

export const dateOptions = [
    {
        label: '天',
        value: 'day',
    },
    {
        label: '週',
        value: 'week',
    },
    {
        label: '月',
        value: 'month',
    },
    {
        label: '年',
        value: 'year',
    },
    {
        label: '累積至今',
        value: 'accumulatedToNow',
    },
]

//跳出錯誤訊息
export const openNotification = (placement, error, key) => {
    notification.warning({
        message: error,
        placement,
        key,
    })
}

//計算累積值
export const calculateCumulationValue = (data = [], key = 'value') => {
    if (data?.length === 0 || data === null) return 0
    const cumulationValue = data?.reduce?.(
        (accumulator, currentValue) => accumulator + (currentValue?.[key] || 0),
        0
    )
    return Number(cumulationValue?.toFixed(2))
}

//計算符合時間區間的資料
export const filterByTimeRange = ({ data = [], timeRange, timescale }) => {
    const { left: startTime, right: endTime, fetch } = timeRange
    if (fetch === false) return []
    // 确保startTime和endTime是有效的
    if (startTime === undefined || endTime === undefined) {
        return []
    }

    // 筛选数据
    return data.filter((item) => {
        // 根据timescale选择时间属性
        const timeProperty = timescale === 'quarter' ? 'time' : 'date'

        // 使用moment.js比较时间
        const itemTime = moment(item[timeProperty])
        const start = moment(startTime)
        const end = moment(endTime)

        return itemTime.isSameOrAfter(start) && itemTime.isSameOrBefore(end)
    })
}

export const calculatePercentage = (val, val2) => {
    if (val2 === 0) {
        // Avoid division by zero
        return 'Undefined'
    }
    const result = ((val - val2) / val2) * 100
    return result.toFixed(2) // Return result rounded to two decimal places
}

export const calculateComplexPercentage = (n, v, n2, v2) => {
    const denominator = n2 + v2

    if (denominator === 0) {
        // Avoid division by zero
        return 'undefined'
    }

    const result = ((n + v - n2 + v2) / denominator) * 100
    return result?.toFixed(2) // Return result rounded to two decimal places
}

//計算充電累積值
export const calculateChargeValue = (data = [], timescale) => {
    if (data.length === 0) return 0
    if (timescale === 'quarter') {
        const totalPositivePower = data?.reduce?.((sum, item) => {
            return sum + (item.power < 0 ? item.power / 4 : 0)
        }, 0)

        return Number(Math.abs(totalPositivePower?.toFixed(2)))
    } else {
        const chargeValue = data?.reduce?.(
            (accumulator, currentValue) =>
                accumulator + (currentValue?.chargeValue || 0),
            0
        )
        return Math.abs(chargeValue?.toFixed(2))
    }
}

//計算放電累積值
export const calculateDischargeValue = (data = [], timescale) => {
    if (data.length === 0) return 0
    if (timescale === 'quarter') {
        const totalNegativePower = data?.reduce?.((sum, item) => {
            return sum + (item.power > 0 ? item.power / 4 : 0)
        }, 0)

        return Number(Math.abs(totalNegativePower?.toFixed(2)))
    } else {
        const dischargeValue = data?.reduce?.(
            (accumulator, currentValue) =>
                accumulator + (currentValue?.dischargeValue || 0),
            0
        )
        return Math.abs(dischargeValue?.toFixed(2))
    }
}

export const formatYAxisTick = (value) => {
    if (value >= 1000) {
        return `${value / 1000}K`
    }
    return value
}

export const transformArray = (arr = []) => {
    // 檢查是否所有物件的 allowedPower 跟 demandLimit 都相同
    const allSame = arr.every((item) => item.allowedPower === item.demandLimit)
    if (allSame) {
        // 如果都相同，返回一個新的陣列只包含 demandLimit、nodeLabel 和 time
        return arr.map((item) => ({
            demandLimit: item.demandLimit,
            nodeLabel: item.nodeLabel,
            time: item.time,
        }))
    } else {
        // 否則返回原陣列
        return arr
    }
}

export const defaultAnalysisMenu = [
    {
        key: 'energyFlow',
        label: '能源流動',
        type: 'group',
        children: [
            {
                key: 'energyControl',
                icon: <FundViewOutlined />,
                label: '能源調度',
            },
            {
                key: 'energyReview',
                icon: <TableOutlined />,
                label: '能源使用總覽',
            },
            {
                key: 'contractStatus',
                icon: <TableOutlined />,
                label: '契約容量狀態',
            },
            {
                key: 'energyTarget',
                icon: <TableOutlined />,
                label: '能源調度指標',
            },
            {
                key: 'carbonEmissions',
                icon: <BarChartOutlined />,
                label: '減少的碳排放量',
            },
        ],
    },
    {
        key: 'energyDevice',
        label: '能源設備',
        type: 'group',
        children: [
            {
                key: 'solarMonitor',
                icon: <LineChartOutlined />,
                label: '太陽能設備',
            },
            {
                key: 'loadMonitor',
                icon: <LineChartOutlined />,
                label: '負載設備',
            },
            {
                key: 'essMonitor',
                icon: <LineChartOutlined />,
                label: '儲能設備',
            },
            {
                key: 'evseMonitor',
                icon: <LineChartOutlined />,
                label: '充電樁設備',
                name: '充電樁設備',
            },
            {
                key: 'windMonitor',
                icon: <LineChartOutlined />,
                label: '風能設備',
            },
        ],
    },
    {
        key: 'economyTarget',
        label: '經濟指標',
        type: 'group',
        children: [
            {
                key: 'greenBenefits',
                icon: <BarChartOutlined />,
                label: '綠電節電效益',
            },
            {
                key: 'essBenefits',
                icon: <BarChartOutlined />,
                label: '儲能節電效益',
            },
            {
                key: 'economyEstimate',
                icon: <BarChartOutlined />,
                label: '經濟效益預估',
            },
        ],
    },
]

const transferLanguage = (txt) => {
    if (txt === 'C') return '離峰'
    if (txt === 'B') return '半尖峰'
    if (txt === 'A') return '尖峰'
    return '未定義'
}

export const getTimePriceSegment = (timestamp, timePriceArray) => {
    if (!timestamp || timePriceArray?.length === 0) return null

    // 将时间戳转换为Moment对象，并只保留时和分
    const timeMoment = moment(timestamp)

    // 找到与给定时间匹配的timePrice段
    const matchedSegment = timePriceArray?.find((segment) => {
        // 将开始和结束时间转换为当天的Moment对象
        let startTime =
            moment(timestamp).format('YYYY/MM/DD') + ' ' + segment.startTime
        let endTime =
            moment(timestamp).format('YYYY/MM/DD') + ' ' + segment.endTime

        // 如果结束时间小于开始时间，认为其跨越了午夜
        if (segment.endTime <= segment.startTime) {
            endTime = moment(endTime).add(1, 'days')
        }

        startTime = moment(startTime, 'YYYY/MM/DD HH:mm:ss')
        endTime = moment(endTime, 'YYYY/MM/DD HH:mm:ss')

        // 检查给定的时间是否在区段的开始和结束时间内
        return timeMoment.isSame(startTime) && timeMoment.isBefore(endTime)
    })

    if (matchedSegment) {
        return {
            time: timestamp,
            peakType: transferLanguage(matchedSegment.peakType),
            weekType: matchedSegment.weekType,
        }
    } else {
        return null
    }
}

//轉換時間
export const timeLineTickFormatter = (num) => {
    let hour = Math.floor(num / 60)
    let minute = num % 60
    if (hour < 10) {
        hour = `0${hour}`
    }
    if (minute < 10) {
        minute = `0${minute}`
    }
    return `${hour}:${minute}:00`
}

export const getTimePriceSegment2 = (timestamp, timePriceArray) => {
    if (timePriceArray?.length === 0) return
    // 將時間戳轉換為Moment對象，並只保留時和分
    const time = timeLineTickFormatter(timestamp)

    // 找到與給定時間匹配的timePrice區段
    const matchedSegment = timePriceArray?.find?.((segment) => {
        // 轉換開始和結束時間為Moment對象
        let startTime = segment.startTime
        let endTime = segment.endTime

        // 檢查給定的時間是否在區段的開始或結束時間
        return startTime == time || endTime == time
    })

    if (matchedSegment) {
        return {
            time: timestamp,
            peakType: transferLanguage(matchedSegment.peakType),
            weekType: matchedSegment.weekType,
        }
    } else {
        return null
    }
}

//繪製圖表
export const drawCharts = (data, time) => {
    const newData = data.filter((item) => {
        return item.time <= Math.round(time * 1.5)
    })
    return newData
}

//繪製圖表2 for 有契約容量
export const drawCharts2 = (data, time) => {
    const indexTime = Math.floor(time / 10)
    const newData = data.map((item, index) => {
        if (index > indexTime) {
            return {
                ...item,
                chargeValue: undefined,
                dischargeValue: undefined,
                essMonitor: undefined,
                grayMonitor: undefined,
                loadMonitor: undefined,
                pvMonitor: undefined,
                wheelMonitor: undefined,
                wheelPvMonitor: undefined,
                soc: undefined,
            }
        } else {
            return item
        }
    })
    return newData
}

//繪製圖表3 for 有預測
export const drawCharts3 = (data, time, key) => {
    const indexTime = Math.floor(time / 10)
    const newData = data.map((item, index) => {
        if (index > indexTime) {
            return {
                ...item,
                [key]: undefined,
            }
        } else {
            return item
        }
    })
    return newData
}

//token過期時間 (30分)
export const expiresIn = 30 * 60 * 1000

//告警層級
export const alertLevelRender = (val) => {
    if (!val) return '-'

    let className
    let icon

    switch (val) {
        case '緊急':
            className = 'alert-icon alert-icon-level1'
            icon = <CloseCircleFilled />
            break
        case '重要':
            className = 'alert-icon alert-icon-level2'
            icon = <MinusCircleFilled />
            break
        case '次要':
            className = 'alert-icon alert-icon-level3'
            icon = <InfoCircleFilled />
            break
        case '提示':
            className = 'alert-icon alert-icon-level4'
            icon = <QuestionCircleFilled />
            break
        default:
            className = ''
            icon = null
            break
    }

    return (
        <>
            <span className={className}>{icon}</span>
            {val}
        </>
    )
}

// 寻找最接近给定时间的对象
export const findClosestTimeObjects = (
    targetTime1,
    targetTime2,
    timeObjects
) => {
    let closestObj1 = null
    let closestObj2 = null
    let smallestDiff1 = Infinity
    let smallestDiff2 = Infinity

    // 將目標時間轉換為毫秒
    const targetTimeMs1 = moment(targetTime1).valueOf()
    const targetTimeMs2 = moment(targetTime2).valueOf()

    // 首先找到最接近 targetTime1 的時間點
    timeObjects.forEach((obj) => {
        const diff = Math.abs(obj.time - targetTimeMs1)
        if (diff < smallestDiff1) {
            smallestDiff1 = diff
            closestObj1 = obj
        }
    })

    // 然後找到最接近 targetTime2 的時間點，但排除與 closestObj1 相同的時間點
    timeObjects.forEach((obj) => {
        const diff = Math.abs(obj.time - targetTimeMs2)
        if (diff < smallestDiff2 && obj !== closestObj1) {
            smallestDiff2 = diff
            closestObj2 = obj
        }
    })

    // 如果找到的時間點相同，或者沒有為 targetTime2 找到更接近的時間點，則嘗試找到另一個最接近的時間點
    if (closestObj1 === closestObj2 || !closestObj2) {
        let nextSmallestDiff = Infinity
        closestObj2 = null // 重置以尋找新的最接近點

        timeObjects.forEach((obj) => {
            const diff = Math.abs(obj.time - targetTimeMs2)
            if (diff < nextSmallestDiff && obj !== closestObj1) {
                nextSmallestDiff = diff
                closestObj2 = obj
            }
        })
    }

    return {
        closestTime1: closestObj1 ? closestObj1.time : null,
        closestTime2: closestObj2 ? closestObj2.time : null,
    }
}

//物件轉URL參數字串
export const queryStringHandler = (initialQuery = {}) => {
    return (
        Object.entries(initialQuery)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined)
            .map(([key, value]) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            })
            .join('&')
    )
}

//清除使用者資訊
export const SessionClear = () => {
    window.localStorage.removeItem('userName')
    window.localStorage.removeItem('expiresAt')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('refreshToken')
}

//計算arr的最高值
export const maxNum = (arr, ...keys) => {
    if (!arr || arr?.length === 0 || keys?.length === 0) {
        return 0
    }
    const maxValues = keys.map((key) =>
        Math.max(...arr.map((item) => item[key] || 0))
    )
    const max = Math.max(...maxValues)
    return max
}

//容器寬高度
const containerWidth = 375
const containerHeight = 295

const createNode = (id, type, x, y, width, height, data) => ({
    id,
    position: {
        x: x * (width / containerWidth),
        y: y * (height / containerHeight),
    },
    type,
    draggable: false,
    data,
})

// edges
const createEdge = (id, source, target, value, sourceHandle, targetHandle) => {
    const isActive = value !== 0
    return {
        id,
        source,
        target,
        type: 'smoothstep',
        animated: isActive,
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: isActive ? 20 : 0,
            height: isActive ? 20 : 0,
            color: '#00cda8',
        },
        style: {
            strokeWidth: 2,
            stroke: isActive ? '#00cda8' : 'rgba(122,122,122,0.3)',
        },
        sourceHandle,
        targetHandle,
    }
}

export const allNodes = ({
    width,
    height,
    pv,
    load,
    ess,
    wind,
    charger,
    green,
    soc,
    taipower,
    timescale,
    isGreen,
    isBehindPv,
    isBehindWind,
}) => {
    const nodes = [
        createNode('energySource', 'energySource', 155, 110, width, height),
    ]

    if (isBehindPv && pv !== undefined && pv !== null) {
        nodes.push(
            createNode('solar', 'solar', 267, 16, width, height, {
                pv,
                timescale,
            })
        )
    }

    if (load !== undefined && load !== null) {
        nodes.push(
            createNode('load', 'load', 60, 205, width, height, {
                load,
                timescale,
            })
        )
    }

    if (ess !== undefined && ess !== null) {
        nodes.push(
            createNode('ess', 'ess', 272, 205, width, height, {
                ess,
                soc,
                timescale,
            })
        )
    }

    if (isBehindWind && wind !== undefined && wind !== null) {
        nodes.push(
            createNode('wind', 'wind', 318, 118, width, height, {
                wind,
                timescale,
            })
        )
    }

    if (charger !== undefined && charger !== null) {
        nodes.push(
            createNode('charger', 'charger', 175, 211, width, height, {
                charger,
                timescale,
            })
        )
    }

    if (isGreen && green !== undefined && green !== null) {
        nodes.push(
            createNode('green', 'green', 177, 5, width, height, {
                green,
                timescale,
            })
        )
    }

    if (taipower !== undefined && taipower !== null) {
        nodes.push(
            createNode('taiPower', 'taiPower', 60, 15, width, height, {
                taipower,
                timescale,
            })
        )
    }

    return nodes
}

export const allEdges = ({
    pv,
    load,
    ess,
    wind,
    charger,
    green,
    taipower,
    isGreen,
    isBehindPv,
    isBehindWind,
}) => {
    const edges = []

    if (isBehindPv && pv !== undefined && pv !== null) {
        edges.push(
            createEdge(
                'energy-solar',
                'solar',
                'energySource',
                pv,
                'top2',
                'ttop2'
            )
        )
    }

    if (load !== undefined && load !== null) {
        edges.push(
            createEdge(
                'energy-load',
                'energySource',
                'load',
                load,
                'bottom',
                null
            )
        )
    }

    if (ess !== undefined && ess !== null) {
        const source = ess < 0 ? 'energySource' : 'ess'
        const target = ess < 0 ? 'ess' : 'energySource'
        edges.push(
            createEdge('energy-ess', source, target, ess, 'bottom2', 'tbottom2')
        )
    }

    if (isBehindWind && wind !== undefined && wind !== null) {
        edges.push(
            createEdge(
                'energy-wind',
                'wind',
                'energySource',
                wind,
                'right2',
                'tright2'
            )
        )
    }

    if (isGreen && green !== undefined && green !== null) {
        edges.push(
            createEdge(
                'energy-green',
                'green',
                'energySource',
                green,
                'topcenter',
                'ttopcenter'
            )
        )
    }

    if (charger !== undefined && charger !== null) {
        edges.push(
            createEdge(
                'energy-charger',
                'load',
                'charger',
                charger,
                'loadRight',
                'tloadRight'
            )
        )
    }

    if (taipower !== undefined && taipower !== null) {
        const source = taipower > 0 ? 'taiPower' : 'energySource'
        const target = taipower > 0 ? 'energySource' : 'taiPower'
        edges.push(
            createEdge(
                'energy-taipower',
                source,
                target,
                taipower,
                'top',
                'ttop'
            )
        )
    }

    return edges
}

export const sceneDataHandler = ({scene, date}) => {
    if (scene === 'VPP0008' && date === '2024-08-23') {
        return scene1;
    } else if (scene === 'VPP0004' && date === '2024-06-06') {
        return scene2;
    } else if (scene === 'VPP0005' && date === '2024-07-04') {
        return scene5;
    } else {
        return null;
    }
}