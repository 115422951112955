import React from 'react'
import { useQuery } from '@tanstack/react-query'
// import moment from 'moment'
import axios from 'axios'
import { getAlertLogList2 } from '../querys/querys.js'
import { AuthContext } from '../store/AuthProvider.js'
import { DEFAULT_TIMEZONE, refetchInterval } from '../functionTool/functionTool'

const useAlertLogList2 = (queryString) => {
    const { user } = React.useContext(AuthContext)

    const { data: alertLogList2, ...arg } = useQuery({
        queryKey: [
            'getAlertLogList2',
            {
                alertLogID: queryString.alertLogID,
                vppID: queryString.vppID,
                alertLevel: queryString.alertLevel,
                checkType: queryString.checkType,
                equipmentLevel: queryString.equipmentLevel,
                expired: queryString.expired,
                alertID: queryString.alertID,
                startDatetime: queryString.startDatetime,
                endDatetime: queryString.endDatetime,
                timezone: DEFAULT_TIMEZONE,
            },
        ],
        queryFn: async ({ queryKey }) => {
            try {
                const response = await axios({
                    method: 'POST',
                    // eslint-disable-next-line no-undef
                    url: process.env.REACT_APP_API,
                    headers: {
                        Authorization: user.token,
                    },
                    data: {
                        query: getAlertLogList2,
                        variables: {
                            alertLogID: queryKey[1].alertLogID,
                            vppID: queryKey[1].vppID,
                            alertLevel: queryKey[1].alertLevel,
                            checkType: queryKey[1].checkType,
                            equipmentLevel: queryKey[1].equipmentLevel,
                            expired:
                                queryKey[1].expired === 'null'
                                    ? undefined
                                    : queryKey[1].expired,
                            alertID: queryKey[1].alertID,
                            timezone: queryKey[1].timezone,
                            startDatetime: queryKey[1].startDatetime,
                            endDatetime: queryKey[1].endDatetime,
                        },
                    },
                })
                return response.data.data // Response received from the API
            } catch (error) {
                return await Promise.reject(error)
            }
        },
        refetchInterval,
        enabled: !!user.token,
    })

    return { alertLogList2, ...arg }
}

export default useAlertLogList2
