import React, { useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
import { SettingContext } from '../../store/SettingProvider'
import { DashboardContext as TimeLineDashboard } from '../../store/TimeLineDashboardProvider'
import useAlertLogList2 from '../../hook/useAlertLogList2'
import logo from '../../images/logo.svg'
import logo_w from '../../images/logo_w.svg'
import logo_icon from '../../images/logo_icon.svg'
import { menu } from '../../functionTool/functionTool'

const { Sider } = Layout
const removeLastSlash = (str) => {
    let input = str
    let parts = input.split('/')
    if (parts.length > 2) {
        parts.pop()
        input = parts.join('/')
    }
    return input
}

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(true)
    const { dark } = useContext(SettingContext)
    const { customTimeLineMode } = useContext(TimeLineDashboard)
    const { alertLogList2 } = useAlertLogList2({ expired: 'false' })
    const { pathname } = useLocation()

    //錯誤訊息數量
    const alerNumber = alertLogList2?.vpp_getAlertLogList2?.filter?.(
        (item) => item.expired !== 'true'
    )?.length

    return (
        <Sider
            collapsible
            className="app-aside"
            collapsed={collapsed}
            collapsedWidth={60}
            onCollapse={(value) => setCollapsed(value)}
            trigger={
                collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />
            }
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <div className="app-logo-wrap">
                <div
                    className={`app-logo ${
                        !collapsed ? '' : 'app-logo-collapsed'
                    }`}
                >
                    {!collapsed ? (
                        <Link to="/">
                            <img
                                src={dark ? logo_w : logo}
                                alt="微電能源-能源管理系統"
                            />
                        </Link>
                    ) : (
                        <Link to="/">
                            <img src={logo_icon} alt="微電能源-能源管理系統" />
                        </Link>
                    )}
                </div>
            </div>
            <Menu
                theme={dark ? 'dark' : 'light'}
                mode="inline"
                className="app-aside-menu"
                inlineIndent={16}
                selectedKeys={[removeLastSlash(pathname)]}
                items={menu({
                    alert: alerNumber,
                    customTimeLineMode,
                })}
            />
        </Sider>
    )
}

export default Sidebar
