import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider, Statistic } from 'antd'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import SettingProvider from './store/SettingProvider.js'
import AuthProvider from './store/AuthProvider.js'
import DashboardProvider from './store/DashboardProvider.js'
import TimeLineDashboardProvider from './store/TimeLineDashboardProvider.js'
import AnalysisProvider from './store/AnalysisProvider.js'
import zhTW from 'antd/lib/locale/zh_TW'
import App from './App'
import axios from 'axios'
import { openNotification, SessionClear } from './functionTool/functionTool'

let abortControllers = new Map()
let isLoggedOut = false
const { Countdown } = Statistic

const logoutUser = () => {
    if (isLoggedOut) return

    isLoggedOut = true
    openNotification(
        'top',
        <div>
            登入逾時，系統將於
            <Countdown
                value={Date.now() + 3 * 1000}
                format="s"
                onFinish={() => (window.location.href = '/login')}
                style={{ display: 'inline-block', padding: '0 2px' }}
                className="logout-count-down"
            />
            秒後自動登出，請再次登入
        </div>,
        '401'
    )

    // 使用AbortController取消所有進行中的request
    abortControllers.forEach((controller, url) => {
        controller.abort()
        abortControllers.delete(url)
    })

    // 重置 AbortController Map
    abortControllers.clear()

    //
    SessionClear()
}

axios.interceptors.response.use(
    (response) => {
        const errorMessage = response?.data?.errors?.[0]?.message
        if (errorMessage) {
            console.warn(errorMessage)
            openNotification(
                'top',
                errorMessage,
                errorMessage
            )
        }
        abortControllers.delete(response.config.url)
        return response
    },
    (error) => {
        abortControllers.delete(error.config?.url)
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    console.warn('未經授權')
                    logoutUser()
                    break
                case 403:
                    console.warn('沒有存取權限')
                    openNotification(
                        'top',
                        '沒有存取權限，無權限使用此動作，如有疑問請通知系統管理員確認權限',
                        '403'
                    )
                    break
                case 404:
                    console.warn('找不到該頁面')
                    break
                case 500:
                    console.warn('伺服器出錯')
                    openNotification('top', '伺服器出錯', '500')
                    break
                case 502:
                    console.warn('伺服器出錯')
                    openNotification('top', '伺服器出錯', '503')
                    break
                case 503:
                    console.warn('服務失效')
                    openNotification('top', '服務失效', '503')
                    break
                default:
                    console.warn(`連接錯誤: ${error.response.status}`)
            }
        }
        return Promise.reject(error)
    }
)

// Create a client
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <SettingProvider>
                <AuthProvider>
                    <AnalysisProvider>
                        <DashboardProvider>
                            <TimeLineDashboardProvider>
                                <ConfigProvider locale={zhTW}>
                                    <App />
                                </ConfigProvider>
                            </TimeLineDashboardProvider>
                        </DashboardProvider>
                    </AnalysisProvider>
                </AuthProvider>
            </SettingProvider>
        </QueryClientProvider>
    </React.StrictMode>
)
