/* eslint-disable react/prop-types */
import React, { Suspense, useContext } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    Navigate,
} from 'react-router-dom'
import { Layout, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Login from './pages/Login'
import { SettingContext } from './store/SettingProvider'
import { AuthContext } from './store/AuthProvider'
import { AnalysisContext } from './store/AnalysisProvider'
import { DashboardContext as TimeLineContext } from './store/TimeLineDashboardProvider'
import './App.less'
import Sidebar from './components/Sidebar'
import Header from './components/Header'
import Footer from './components/Footer'
import AnalysisSidebar from './components/AnalysisSidebar'

const Dashboard = React.lazy(() => import('./pages/dashboard'))
const TimeLineDashboard = React.lazy(() => import('./pages/TimeLineDashboard'))
const NotFound = React.lazy(() => import('./pages/NotFound'))
const NotFoundInside = React.lazy(() => import('./pages/NotFoundInside'))
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'))
const Device = React.lazy(() => import('./pages/Device'))
const DeviceView = React.lazy(() => import('./pages/DeviceView'))
const Analysis = React.lazy(() => import('./pages/Analysis'))
const ContractConfig = React.lazy(() => import('./pages/ContractConfig'))
const ContractConfigView = React.lazy(() =>
    import('./pages/ContractConfigView')
)
const Settings = React.lazy(() => import('./pages/Settings'))
const Abnormal = React.lazy(() => import('./pages/Abnormal'))
const AbnormalView = React.lazy(() => import('./pages/AbnormalView'))
const GreenEnergyReport = React.lazy(() => import('./pages/GreenEnergyReport'))
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 48,
        }}
        spin
    />
)

function App() {
    const { customTimeLineMode } = useContext(TimeLineContext)
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<NavWrapper />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route
                        path="/timelinedashboard"
                        element={customTimeLineMode ? <TimeLineDashboard /> : <Navigate to="/" />}
                    />
                    <Route
                        path="/changepassword"
                        element={<ChangePassword />}
                    />
                    <Route path="/device" element={<Device />} />
                    <Route path="/device/:id" element={<DeviceView />} />
                    <Route path="/controls" element={<NotFoundInside />} />
                    <Route path="/analysis" element={<Analysis />} />
                    <Route
                        path="/contractconfig"
                        element={<ContractConfig />}
                    />
                    <Route
                        path="/contractconfig/:id"
                        element={<ContractConfigView />}
                    />
                    <Route path="/abnormal" element={<Abnormal />} />
                    <Route path="/abnormal/:id" element={<AbnormalView />} />
                    <Route
                        path="/greenenergyreport"
                        element={<GreenEnergyReport />}
                    />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/*" element={<NotFoundInside />} />
                </Route>
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </Router>
    )
}

function NavWrapper() {
    const { dark } = useContext(SettingContext)
    const { user } = useContext(AuthContext)
    const { isDraggable } = useContext(AnalysisContext)
    const sidebar = isDraggable ? <AnalysisSidebar /> : <Sidebar />
    if (!user) return <Navigate to="/login" />

    return (
        <Layout className={`app-layout ${dark ? 'dark' : ''}`}>
            {sidebar}
            <Layout className="app-background">
                <Header />
                <Suspense
                    fallback={
                        <div className="app-content loading">
                            <Spin indicator={antIcon} />
                        </div>
                    }
                >
                    <Outlet />
                </Suspense>
                <Footer />
            </Layout>
        </Layout>
    )
}

export default App
