/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'
import useLocalStorage from '../hook/useLocalStorage'

export const SettingContext = createContext()

export default function SettingProvider({ children }) {
    const [dark, setDark] = useLocalStorage('dark', 'true')
    const [count, setCount] = useState(8)

    const defaultValue = {
        dark,
        setDark,
        count,
        setCount,
    }

    return (
        <SettingContext.Provider value={defaultValue}>
            {children}
        </SettingContext.Provider>
    )
}
