import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'
import { DownOutlined, HolderOutlined } from '@ant-design/icons'
import { SettingContext } from '../../store/SettingProvider'
import { AnalysisContext } from '../../store/AnalysisProvider'
import { defaultAnalysisMenu } from '../../functionTool/functionTool'
import logo from '../../images/logo.svg'
import logo_w from '../../images/logo_w.svg'

const { Sider } = Layout

function excludeChildrenByKey(menu, excludeArray) {
    const excludeKeys = excludeArray.map((item) => item.key)
    return menu.map((item) => {
        const children = item.children.filter((child) => {
            return excludeKeys.includes(child.key)
        })
        const obj = {
            ...item,
            children,
        }
        return obj
    })
}

const AnalysisSidebar = () => {
    const { dark } = useContext(SettingContext)
    const { analysisMenu, setDragNum } = useContext(AnalysisContext)
    const filterMenu = excludeChildrenByKey(defaultAnalysisMenu, analysisMenu)

    return (
        <Sider
            collapsible
            className="app-aside"
            collapsed={false}
            trigger={null}
            collapsedWidth={60}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <div className="app-logo-wrap">
                <div className={`app-logo`}>
                    <Link to="/">
                        <img
                            src={dark ? logo_w : logo}
                            alt="微電能源-能源管理系統"
                        />
                    </Link>
                </div>
            </div>
            <ul className="app-analysis-menu">
                {filterMenu.map((item) => {
                    return (
                        <li key={item.key}>
                            {item.label} {<DownOutlined />}
                            {
                                <ul>
                                    {item.children.map((child) => (
                                        <li
                                            draggable="true"
                                            key={child.key}
                                            onDragStart={() => {
                                                setDragNum(child.key)
                                            }}
                                        >
                                            {<HolderOutlined />}
                                            {child.icon}
                                            {child.label}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </li>
                    )
                })}
            </ul>
        </Sider>
    )
}

export default AnalysisSidebar
